// add customs styles and general overrides here
// due to the cascading nature of css, if you try to override theme css variables in this file, those changes will not apply. Instead, override css variables in the `override.sass` file
// we recommend not editing this file directly. Instead, create an `assets/sass/_custom.sass` file at the root level of your site.
// if you edit this file directly, you will have to resolve git conflicts when and if you decide to pull changes we make on the theme

ul.sidebar-recent {
  padding-left: 0;
  li {
    list-style: none;
    display: inline-block;
    height: 80px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-bottom: 15px;
    img {
      width: 80px;
      height: 80px;
      overflow: hidden;
      display: block;
      margin: 0;
      float: left;
      margin-right: 9px;
      border-radius: 15px; }
    p {
      padding: 0;
      margin: 0;
      font-size: 14px;
      margin-top: 10px; } } }

h2 {
  font-weight: 600; }

.post_link {
  a {
    font-weight: 600; } }
